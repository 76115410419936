<template>
  <div class="user_info">
    <navigationBar :showBack="showBack">个人信息</navigationBar>
    <div class="menu_box">
      <van-cell-group>
        <van-cell title="用户名" :value="userName" />
        <van-cell title="真实姓名" :value="fullName" />
        <van-cell title="手机号" :value="phone" />
        <van-cell title="身份证号" :value="id" />
        <van-cell title="机构/学校" :value="school" />
        <van-cell title="班级" :value="team" />
        <van-cell title="用户群体" v-if="role == 5" value="学生" />
        <van-cell title="用户群体" v-if="role == 6" value="军人" />
        <van-cell title="用户群体" v-if="role == 7" value="农民" />
        <van-cell title="用户群体" v-if="role == 8" value="其他" />
        <van-cell title="用户群体" v-if="role == 9" value="残障人士" />
      </van-cell-group>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import navigationBar from "@/components/navigationBar/navigationBar";
import { getUserInfo } from "@/request/api";
export default {
  data() {
    return {
      loginUser: {},
      dataList: [],
      userName: "",
      fullName: "",
      phone: "",
      id: "",
      school: "",
      team: "",
      role: 0,
      showBack: true
    };
  },
  components: {
    navigationBar,
  },

  created() {
    this.loginUser = JSON.parse(localStorage.getItem("loginUser"));
  },

  mounted() {
    this.getUserInfo(this.loginUser.id)
  },

  methods: {
    getUserInfo(id) {
      getUserInfo(id).then(res => {
        console.log(res)
        this.userName = res.data.name;
        this.fullName = res.data.realName;
        this.phone = res.data.phoneNum;
        this.id = res.data.cardNum;
        this.school = res.data.institutionName;
        this.team = res.data.className;
        this.role = res.data.role;
      })
    }
  }
};
</script>

<style scoped lang="less">
.user_info {
  margin-top: 0.88rem;
  padding: 0 0 0.98rem 0;
  min-height: calc(100vh - 0.88rem);
  background-color: #f4f4f4;
  overflow: hidden;

  .menu_box {
    margin-top: 0.2rem;
  }
}

/deep/ .van-cell {
  padding: 0.28rem 0.3rem;
  font-size: 0.32rem;
  color: #333333;
  margin-bottom: 0.08rem;
}

/deep/ .van-cell__value {
  font-size: 0.26rem;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  color: #999999;
}
</style>
